export default function dragHighlightPos(scheduler){
    let dndMarkers = [];
    let dragStarted = false;
    let eventNode = null;
    let event = null;

    function isEnabled() { 
        return scheduler.config.drag_highlight && scheduler.markTimespan;
    }

    function checkViewName(viewName){
        const viewObj = scheduler.getView(viewName);
        if(viewObj){
            return viewObj.layout;
        }
        return viewName;
    }

    function checkSectionPropertyName(viewName){
        const viewObj = scheduler.getView(viewName);
        if(viewObj.y_property){
            return viewObj.y_property;
        }
        if(viewObj.map_to){
            return viewObj.map_to;
        }
    }

    function setRequiredStylesToMarker(eventNode, layout){
        switch (layout) {
            case "month":
                eventNode.style.top = "";
                eventNode.style.left = "";
                break;
            case "timeline":
                eventNode.style.left = "";
                eventNode.style.marginLeft = "1px";
                break;
            default:
                eventNode.style.top = "";
                break;
        }
    }
    
    function createMarkerConfig(configSettings){
        const { event, layout, viewName, sectionId, eventNode } = configSettings;
        setRequiredStylesToMarker(eventNode, layout);
        const sections = {};
        let markerObject = {
            start_date: event.start_date,
            end_date: event.end_date,
            css: "dhx_scheduler_dnd_marker",
            html: eventNode
        };
        //GS-2644: case for round_position config in timeline view
        if (layout == "timeline") {
            const viewObj = scheduler.getView(viewName);
            if (viewObj.round_position) {
                const index = scheduler._get_date_index(viewObj,event.start_date);
                const rounded_date = viewObj._trace_x[index];
                markerObject.start_date = rounded_date;
            }
        }
        if (layout == "timeline" || layout == "month" ){
            // we need to set end date less than 24 hours of the views to avoid receiving multiple marktimespans
            markerObject = {...markerObject, end_date: scheduler.date.add(event.start_date, 1, 'minute')};
        }
        if (sectionId){
            sections[viewName] = sectionId;
            markerObject.sections = sections;
        }
        return markerObject;
    }

    function createViewMarker(settings){
        const { layout } = settings;
        let markerConfigs;
        switch (layout) {
            case "month": 
                markerConfigs = getMonthViewMarkers(settings);
                break;
            case 'timeline':
            case 'units':
                markerConfigs = getTimelineAndUnitsViewMarkers(settings);
                break;
            default: 
                markerConfigs = getColumnViewMarkers(settings);
                break;
        }
        markerConfigs.forEach((cfg) => {
            dndMarkers.push(scheduler.markTimespan(cfg));
        });
    }

    function getColumnViewMarkers(settings){
        const {event, layout, viewName, sectionId} = settings;
        let columnViewMarkersArray = [];
            let eventNodes = scheduler.$container.querySelectorAll(`[${scheduler.config.event_attribute}='${event.id}']:not(.dhx_cal_select_menu):not(.dhx_drag_marker)`);
            if(eventNodes){
                for (let i = 0; i < eventNodes.length; i++) {
                    let eventNodeClone = eventNodes[i].cloneNode(true);
                    let startDate = new Date(+eventNodeClone.getAttribute("data-bar-start"));
                    let endDate = new Date(+eventNodeClone.getAttribute("data-bar-end"));
                    let dates = {start_date: startDate, end_date: endDate};
                    const configSettings = {
                        event: dates, 
                        layout, 
                        viewName, 
                        sectionId, 
                        eventNode:eventNodeClone
                    };
                    columnViewMarkersArray.push(createMarkerConfig(configSettings));
                }	
            }
        return columnViewMarkersArray;
    }

    function getMonthViewMarkers(settings){
        let monthViewMarkersArray = [];
        const {event, layout, viewName, sectionId} = settings;
        const weekDates = [];
        let currDate = new Date(event.start_date);
        while(currDate.valueOf() < event.end_date.valueOf()){
            let obj = {start_date: currDate};
            weekDates.push(obj);
            currDate = scheduler.date.week_start(scheduler.date.add(currDate, 1, "week"));
        }
        let cells = scheduler.$container.querySelectorAll(`[${scheduler.config.event_attribute}='${event.id}']`);
        for (let i = 0; i < cells.length; i++) {
            const configSettings = {
                event: weekDates[i], 
                layout, 
                viewName, 
                sectionId, 
                eventNode: cells[i].cloneNode(true)
            };
            monthViewMarkersArray.push(createMarkerConfig(configSettings));
        }
        return monthViewMarkersArray;
    }

    function getTimelineAndUnitsViewMarkers(settings){
        let unitMarkersArray = [];
        const {event, layout, viewName, eventNode} = settings;
        let sectionPropertyName = checkSectionPropertyName(viewName);
        if (sectionPropertyName){
            const sections = String(event[sectionPropertyName]).split(scheduler.config.section_delimiter);
            const formatedSections = sections.map(element => String(element));
            const elems = [];
            for (let i = 0; i < formatedSections.length; i++) {
                elems[i] = eventNode.cloneNode(true);
                const configSettings = {
                    event, 
                    layout, 
                    viewName, 
                    sectionId: formatedSections[i],
                    eventNode: elems[i]
                };
                unitMarkersArray.push(createMarkerConfig(configSettings));
            }
        }
        return unitMarkersArray;
    }
    
    scheduler.attachEvent("onBeforeDrag", function (id, mode, e){
        if (isEnabled()) {
            dragStarted = true;
            event = scheduler.getEvent(id);
            eventNode = e.target.closest(`[${scheduler.config.event_attribute}]`);
            const viewName = scheduler.getState().mode;
            const layout = checkViewName(viewName);
            // to avoid incorrect eventNode and marker creation when scheduler.config.cascade_event_display included
            if (layout == "units" && scheduler.config.cascade_event_display ) {
                scheduler.unselect(id);
                eventNode = e.target.closest(`[${scheduler.config.event_attribute}]`);
            }
        }
        return true;
    });

    scheduler.attachEvent("onEventDrag", function (id, mode, e){
        if(dragStarted && isEnabled()){
            dragStarted = false;// insert element only once after dnd start
            const viewName = scheduler.getState().mode;
            const layout = checkViewName(viewName);
            const sectionId = scheduler.getActionData(e).section;
            if (event) {
                const settings = {
                    event, 
                    layout, 
                    viewName, 
                    sectionId, 
                    eventNode
                };
                createViewMarker(settings);
            }
        }
    });

    scheduler.attachEvent("onDragEnd", function(id, mode, e){
        for (let i = 0; i < dndMarkers.length; i++) {
            scheduler.unmarkTimespan(dndMarkers[i]);
        }
        dndMarkers = [];
        eventNode = null;
        event = null;
    });
}